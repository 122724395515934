import React from 'react'
import Card from '../components/card'
import ClosestStation from '../components/closestStation'
import MapProfile from '../components/mapProfile'
import StationsDropdownV2 from '../components/stationsDropdownV2'
import { useAuth0 } from '../context/react-auth0-spa'
import { orderStationsByDistanceFromUser } from '../utils/helpers'
import useStations from '../utils/hooks/useStations'

export default function FavoriteStations({ tabNum }) {
  const { user, setUser } = useAuth0()
  const { position } = useAuth0()
  const {
    station,
    setStation,
    stationList,
    favoriteStationsUnformatted,
    geoJSON,
  } = useStations()

  let allStationsUnformatted = []
  stationList.forEach((state) => {
    state.options.map((stn) => allStationsUnformatted.push(stn))
  })

  function removeFavoriteStation(stn) {
    const fs = favoriteStationsUnformatted.filter((s) => s.id !== stn.id)
    setUser({ ...user, favoriteStations: fs })
    if (fs.length === 0) setStation(null)
  }

  let closestStations = []
  if (position && allStationsUnformatted) {
    closestStations = orderStationsByDistanceFromUser(
      position,
      allStationsUnformatted,
    ).slice(0, 3)
  }

  return (
    <div className="mx-1 mb-10">
      <section className="mt-3">
        <div className="container mx-auto">
          <h2 className="text-2xl font-semibold text-center lg:text-3xl">
            Your Favorite Weather Stations
          </h2>
          <p className="text-center md:text-lg">
            Choose or edit your favorite stations
          </p>
        </div>
      </section>

      <p className="hidden max-w-2xl px-6 py-1 mx-auto sm:block md:text-lg lg:px-0">
        Choose or edit your favorite stations from the dropdown list or type
        into the dropdown, then click or tap the station name. Or click or tap
        the station on the map. Use the Remove button to remove them. Choices
        and edits are saved immediately.
      </p>

      <p className="max-w-2xl px-6 py-1 mx-auto sm:hidden md:text-lg lg:px-0">
        Choose or edit your favorite stations from the dropdown list or type
        into the dropdown, then click or tap the station name. Use the Remove
        button to remove them. Choices and edits are saved immediately.
      </p>

      <p className="max-w-2xl px-6 py-1 mx-auto text-sm lg:px-0">
        Next, click or tap on NEWA Tools or go to your Dashboard.
      </p>

      <p className="max-w-2xl px-6 py-1 mx-auto text-sm lg:px-0">
        On your Dashboard, edit the Station Overview to display the weather data
        you want.
      </p>

      <p className="max-w-2xl px-6 py-4 mx-auto text-sm lg:px-0">
        If you own a station(s), NEWA auto-generates a favorites list for you,
        if your name matches a weather station owner name.
      </p>

      <ClosestStation closestStations={closestStations}></ClosestStation>

      <section className="pt-8">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 px-4 pb-10 gap-x-12 xl:grid-cols-2 favorites">
            <Card
              title="Your Favorite Stations"
              color="primary"
              icon={true}
              favoriteStations={favoriteStationsUnformatted}
            >
              <div className="flex flex-col">
                <div className="w-full px-2 mx-auto mb-4 mt-2 sm:w-96">
                  <StationsDropdownV2
                    options={stationList}
                    value={station}
                    setValue={setStation}
                    isProfile={true}
                  ></StationsDropdownV2>
                </div>

                {/* Favorite Stations List */}
                <div className="overflow-y-scroll h-96">
                  {favoriteStationsUnformatted.length !== 0 &&
                    favoriteStationsUnformatted.map((stn, i) => {
                      return (
                        <div
                          key={i}
                          className={`px-6 py-2 mb-2 ${
                            i % 2 === 0 ? 'white' : 'bg-gray-100'
                          }`}
                        >
                          <div className="flex items-center justify-between">
                            <span className="mr-2">
                              <svg
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="w-6 h-6 text-orange-400 star"
                              >
                                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                              </svg>
                            </span>
                            <div className="w-full">
                              <span className="text-xs text-left sm:text-sm md:text-base">
                                {stn.name}, {stn.state}
                              </span>
                              {!stn.activeStatus && (
                                <span className="text-xs text-red-600 ml-1 border rounded-md py-0.5 px-1 border-red-500">
                                  Inactive
                                </span>
                              )}
                            </div>
                            <button
                              className={`px-2 py-1 font-semibold tracking-wide text-gray-600 bg-white border border-gray-600 hover:bg-gray-600 hover:text-white text-xs rounded-md`}
                              onClick={() => removeFavoriteStation(stn)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </Card>

            <div
              className="hidden mt-12 sm:block xl:mt-0 w-full"
              aria-label="NEWA stations map"
            >
              <Card
                title="Click or tap on a station marker to add to favorites"
                color="primary"
              >
                <div
                  aria-hidden="true"
                  className="relative h-96 lg:h-128 w-full"
                >
                  {stationList && (
                    <MapProfile
                      station={station}
                      setStation={setStation}
                      allStations={stationList}
                      favoriteStations={favoriteStationsUnformatted}
                      allStationsUnformatted={allStationsUnformatted}
                      tabNum={tabNum}
                      geoJSON={geoJSON}
                    ></MapProfile>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
